import { useEffect, useState } from 'react';
import { User } from '../models/user';

interface FriendRequest {
  request_id: string;
  requester_id: string;
  recipient_id: string;
  status: string;
}

export function useFriendRequests() {
  const [incomingRequests, setIncomingRequests] = useState<FriendRequest[]>([]);
  const [sentRequests, setSentRequests] = useState<FriendRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  async function fetchFriendRequests() {
    try {
      setLoading(true);
      setError(null);

      const [incoming, sent] = await Promise.all([
        User.getFriendRequests(),
        User.getSentFriendRequests(),
      ]);

      setIncomingRequests(incoming);
      setSentRequests(sent);
    } catch (err) {
      setError('Failed to load friend requests');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchFriendRequests();
  }, []);

  return {
    incomingRequests,
    sentRequests,
    loading,
    error,
    refresh: fetchFriendRequests, // Expose the fetch function for manual refreshes
  };
} 