/**
 * useUserProfile Hook
 *
 * This hook manages the user profile data with caching and automatic updates.
 *
 * Functionality:
 * 1. Caches user profile data in localStorage with a 24-hour expiry.
 * 2. Automatically loads user profile on initial mount.
 * 3. Provides a function to manually reload the user profile.
 * 4. Manages loading state during profile fetching.
 *
 * Usage:
 * const { userProfile, isLoading, loadUserProfile } = useUserProfile();
 *
 * - userProfile: The current user profile data (null if not loaded)
 * - isLoading: Boolean indicating if the profile is being fetched
 * - loadUserProfile: Function to manually trigger a profile reload
 *
 * The hook will cause a re-render when:
 * - The initial profile is loaded
 * - The profile is manually reloaded using loadUserProfile
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, a new profile is fetched from the server
 */

import { useState, useEffect, useCallback } from 'react';
import { User } from '../models/user';

const getCurrentUserId = (): string | null => {
	const token = localStorage.getItem('accessToken');
	if (token) {
		const payloadBase64 = token.split('.')[1];
		const payload = atob(payloadBase64);
		const parsedPayload = JSON.parse(payload);
		return parsedPayload.userId;
	}
	return null;
};

export const useUserProfile = () => {
	const userId = getCurrentUserId();
	const CACHE_KEY = `userProfileCache_${userId}`;

	const loadFromLocalStorage = (): User | null => {
		try {
			const cachedData = localStorage.getItem(CACHE_KEY);
			if (cachedData) {
				return JSON.parse(cachedData) as User;
			}
		} catch (error) {
			console.error('Failed to load user profile from localStorage:', error);
		}
		return null;
	};

	const [userProfile, setUserProfile] = useState<User | null>(loadFromLocalStorage());
	const [isLoading, setIsLoading] = useState(false);

	const saveToLocalStorage = (data: User) => {
		try {
			localStorage.setItem(CACHE_KEY, JSON.stringify(data));
		} catch (error) {
			console.error('Failed to save user profile to localStorage:', error);
		}
	};

	useEffect(() => {
		// If we don't have a user ID, we can't load the profile
		if (!userId) {
			setUserProfile(null);
			setIsLoading(false);
			return;
		}

		const cachedProfile = loadFromLocalStorage();
		if (cachedProfile) {
			setUserProfile(cachedProfile);
		}

		const fetchUserProfile = async () => {
			setIsLoading(true);
			try {
				const userData = await User.fetchCurrentUser();
				if (JSON.stringify(userData) !== JSON.stringify(cachedProfile)) {
					saveToLocalStorage(userData);
					setUserProfile(userData);
				}
			} catch (error) {
				console.error('Error fetching user profile:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchUserProfile();
	}, [userId]); // Depend on userId to refetch if the user changes

	return { userProfile, isLoading };
};
