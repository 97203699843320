import React from 'react';

const Modal = ({ children }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg overflow-auto max-h-full">
                {children}
            </div>
        </div>
    );
};

export default Modal;