/**

 * useUserTeams Hook
 *
 * This hook manages the user's teams data, providing efficient caching and
 * optimistic updates for a responsive user experience.
 *
 * Functionality:
 * 1. Immediately returns cached teams data if available and not expired.
 * 2. Asynchronously fetches and updates teams data when needed.
 * 3. Implements local storage caching with a 24-hour expiration.
 * 4. Provides optimistic updates for adding new teams.
 * 5. Manages loading and error states.
 *
 * Usage:
 * const { userTeams, isTeamsLoading, error, addTeam, deleteTeam } = useUserTeams();
 *
 * - userTeams: Array of Team objects or null if not loaded
 * - isTeamsLoading: Boolean indicating if teams are being fetched
 * - error: Error object if an error occurred during fetching
 * - addTeam: Function to optimistically add a new team
 * - deleteTeam: Function to optimistically delete a team
 *
 * The hook will cause a re-render when:
 * - Initial cached data is loaded
 * - New teams data is fetched
 * - A team is added or deleted (optimistically and after confirmation)
 * - Loading state changes
 * - An error occurs
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, new data is fetched from the server
 * - Cache expiration is set to 24 hours
 *
 * Note: This hook depends on the useUserProfile hook for the current user's ID.
 */

import { useState, useEffect } from 'react';
import { useUserProfile } from './useUserProfile';
import { Team } from '../models/teams';

const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Function to sort teams by their name (ascending order)
const sortTeamsByName = (teams: Team[]): Team[] => {
	return teams.sort((a, b) => a.name.localeCompare(b.name));
};

// Ensure that each team in userTeams has a getStandings method
const enhanceTeamsWithStandings = (teams: Team[]) => {
	return teams.map((team) => ({
		...team,
		getStandings: async () => {
			return await team.getStanding();
		},
	}));
};

export const useUserTeams = () => {
	const { userProfile } = useUserProfile();
	const [userTeams, setUserTeams] = useState<Team[] | null>(null); // Initialize as null
	const [isTeamsLoading, setIsTeamsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [refreshKey, setRefreshKey] = useState(0);

	const refreshTeams = () => {
		setRefreshKey((prev) => prev + 1);
	};

	useEffect(() => {
		if (!userProfile) {
			console.log('useUserTeams: userProfile is not available');
			setIsTeamsLoading(false);
			return; // Wait for userProfile
		}

		const CACHE_KEY = `userTeamsCache_${userProfile.user_id}`;
		const cachedTeams = localStorage.getItem(CACHE_KEY);

		if (cachedTeams) {
			const { data, timestamp } = JSON.parse(cachedTeams);
			if (Date.now() - timestamp < CACHE_EXPIRY) {
				let validData = data.filter(isValidTeam);
				validData = sortTeamsByName(validData); // {{ edit_1 }}
				setUserTeams(validData);
				console.log('useUserTeams: Using cached and sorted teams data'); // {{ edit_1 }}
				// Do not return here; proceed to fetch the latest data
			} else {
				localStorage.removeItem(CACHE_KEY); // Remove expired data
			}
		}

		const fetchUserTeams = async () => {
			setIsTeamsLoading(true);
			try {
				const teams = await Team.getByUserId(userProfile.user_id);
				let validTeams = teams.filter(isValidTeam);
				validTeams = sortTeamsByName(validTeams); // {{ edit_2 }}
				setUserTeams((prevTeams) => {
					if (!prevTeams) return validTeams;
					const teamIds = new Set(prevTeams.map((team) => team.team_id));
					const newUniqueTeams = validTeams.filter((team) => !teamIds.has(team.team_id));
					return sortTeamsByName([...prevTeams, ...newUniqueTeams]);
				});
				localStorage.setItem(
					CACHE_KEY,
					JSON.stringify({
						data: validTeams,
						timestamp: Date.now(),
					})
				);
				console.log('useUserTeams: Teams fetched, sorted, and cached successfully'); // {{ edit_2 }}
			} catch (err) {
				setError(err as Error);
				console.error('Error fetching user teams:', err);
			} finally {
				setIsTeamsLoading(false);
			}
		};

		fetchUserTeams();
	}, [userProfile, refreshKey]); // Included refreshKey in dependencies

	const addTeam = async (newTeam: Team) => {
		/* edit_3 */
		if (!isValidTeam(newTeam)) {
			console.warn('Attempted to add an invalid team:', newTeam);
			return;
		}

		if (!userTeams || !userProfile) return;

		const updatedTeams = sortTeamsByName([...userTeams, newTeam]); // {{ edit_3 }}
		setUserTeams((prevTeams) => {
			if (!prevTeams) return updatedTeams;
			const teamIds = new Set(prevTeams.map((team) => team.team_id));
			const newUniqueTeams = updatedTeams.filter((team) => !teamIds.has(team.team_id));
			return sortTeamsByName([...prevTeams, ...newUniqueTeams]);
		});

		const CACHE_KEY = `userTeamsCache_${userProfile.user_id}`;
		localStorage.setItem(
			CACHE_KEY,
			JSON.stringify({
				data: updatedTeams,
				timestamp: Date.now(),
			})
		);

		try {
			await newTeam.create();
			console.log('useUserTeams: Team added successfully');
			refreshTeams(); // Trigger refresh to get latest data
		} catch (err) {
			// Revert the optimistic update on failure
			const revertedTeams = userTeams.filter((team) => team.team_id !== newTeam.team_id);
			setUserTeams((prevTeams) => {
				if (!prevTeams) return revertedTeams;
				const teamIds = new Set(prevTeams.map((team) => team.team_id));
				const newUniqueTeams = revertedTeams.filter((team) => !teamIds.has(team.team_id));
				return sortTeamsByName([...prevTeams, ...newUniqueTeams]);
			});
			localStorage.setItem(
				CACHE_KEY,
				JSON.stringify({
					data: sortTeamsByName(revertedTeams), // {{ edit_4 }}
					timestamp: Date.now(),
				})
			);
			console.error('Error adding team:', err);
		}
	}; /* edit_3 */

	const deleteTeam = async (teamId: string) => {
		/* edit_5 */
		if (!userTeams || !userProfile) return;

		const CACHE_KEY = `userTeamsCache_${userProfile.user_id}`;
		const originalTeams = [...userTeams];

		// Optimistically remove the team from state and cache
		const updatedTeams = sortTeamsByName(userTeams.filter((team) => team.team_id !== teamId)); // {{ edit_5 }}
		setUserTeams((prevTeams) => {
			if (!prevTeams) return updatedTeams;
			const teamIds = new Set(prevTeams.map((team) => team.team_id));
			const newUniqueTeams = updatedTeams.filter((team) => !teamIds.has(team.team_id));
			return sortTeamsByName([...prevTeams, ...newUniqueTeams]);
		});
		localStorage.setItem(
			CACHE_KEY,
			JSON.stringify({
				data: updatedTeams,
				timestamp: Date.now(),
			})
		);

		try {
			const team = await Team.fetchById(teamId);
			await team.delete(); // Ensure you have this method implemented
			console.log(`useUserTeams: Team with ID ${teamId} deleted successfully`);
		} catch (err) {
			// Revert the optimistic update on failure
			setUserTeams(sortTeamsByName(originalTeams)); // {{ edit_5 }}
			localStorage.setItem(
				CACHE_KEY,
				JSON.stringify({
					data: sortTeamsByName(originalTeams), // {{ edit_5 }}
					timestamp: Date.now(),
				})
			);
			console.error(`Error deleting team with ID ${teamId}:`, err);
		}
	}; /* edit_5 */

	return { userTeams, isTeamsLoading, error, addTeam, deleteTeam }; /* edit_start */
};

function isValidTeam(team: Team): boolean {
    return true;
}