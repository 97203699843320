import React, { useState, useRef, useEffect } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import FormField from './FormField';

// Define interfaces
interface Suggestion {
  description: string;
  placeId: string;
}

interface AutocompleteResponse {
  suggestions: Array<{
    placePrediction: {
      place: string;
      placeId: string;
      text: {
        text: string;
        matches: Array<{ endOffset: number }>;
      };
      // Add other fields if necessary
    };
  }>;
}

interface PlaceDetailsResponse {
  place: {
    location: {
      lat: number;
      lng: number;
    };
    // Add other fields if necessary
  };
}

interface LocationPickerProps {
  location: string;
  setLocation: (location: string) => void;
  setCoordinates: (coordinates: { lat: number; lng: number } | null) => void;
}

const LocationPicker: React.FC<LocationPickerProps> = ({ location, setLocation, setCoordinates }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [userLocations, setUserLocations] = useState<string[]>([]);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]); // Update state type for suggestions
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchUserLocations = async () => {
    //   const locations = await useUserLocations();
    //   setUserLocations(locations);
    };
    fetchUserLocations();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLocation(value);
    if (value.length > 2) {
      fetchSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (description: string, placeId: string) => {
    setLocation(description);
    setShowDropdown(false);
    fetchCoordinates(placeId);
  };

  // Update fetchSuggestions function
  const fetchSuggestions = async (input: string) => {
    try {
      const response = await fetch('https://places.googleapis.com/v1/places:autocomplete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': 'AIzaSyCywtheAnFb8j2NPTxoiZAjYqc7oixpTNw', // Replace with your API key
        },
        body: JSON.stringify({
          input: input,
          // Optionally include locationBias
        }),
      });
      const data: AutocompleteResponse = await response.json();
      if (data.suggestions) {
        const suggestionList: Suggestion[] = data.suggestions.map((suggestionItem) => ({
          description: suggestionItem.placePrediction.text.text,
          placeId: suggestionItem.placePrediction.placeId,
        }));
        setSuggestions(suggestionList);
      } else {
        setSuggestions([]);
        console.error('Error fetching suggestions:', data);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  };

  // Update fetchCoordinates function
  const fetchCoordinates = async (placeId: string) => {
    try {
      const response = await fetch(`https://places.googleapis.com/v1/places/${placeId}?fields=location`, {
        method: 'GET',
        headers: {
          'X-Goog-Api-Key': 'AIzaSyCywtheAnFb8j2NPTxoiZAjYqc7oixpTNw', // Replace with your API key
        },
      });
      const data: PlaceDetailsResponse = await response.json();
      if (data.place && data.place.location) {
        const location = data.place.location;
        setCoordinates({ lat: location.lat, lng: location.lng });
      } else {
        setCoordinates(null);
        console.error('Error fetching coordinates:', data);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      setCoordinates(null);
    }
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <FormField
        label="Location"
        icon={<FaMapMarkerAlt className="text-gray-400 mr-2" />}
        // error={error} // Include error handling if necessary
      >
        <input
          type="text"
          value={location}
          onChange={handleInputChange}
          placeholder="Select a location"
          onFocus={handleInputFocus}
          // Remove className since FormField handles styling
        />
      </FormField>
      {showDropdown && (
        <div
          className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden animate-fade-in z-10"
          style={{ top: '100%' }}
        >
          {/* User Locations */}
          {userLocations.length > 0 && (
            <div className="border-b border-gray-200">
              <div className="px-4 py-2 text-gray-700 font-semibold">Your Locations</div>
              {userLocations.map((loc, index) => (
                <div
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-indigo-50 transition duration-300"
                  onClick={() => handleSelect(loc, '')}
                >
                  {loc}
                </div>
              ))}
            </div>
          )}
          {/* Suggestions */}
          {suggestions.length > 0 && (
            <div>
              <div className="px-4 py-2 text-gray-700 font-semibold">Suggestions</div>
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="px-4 py-2 cursor-pointer hover:bg-indigo-50 transition duration-300"
                  onClick={() => handleSelect(suggestion.description, suggestion.placeId)}
                >
                  {suggestion.description}
                </div>
              ))}
            </div>
          )}
          {/* No Suggestions */}
          {suggestions.length === 0 && location.length > 2 && (
            <div className="p-4 text-gray-500">No results found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default LocationPicker;
