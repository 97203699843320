import React, { useState } from 'react';
import TopNavBar from '../components/TopNavBar';
import { Game } from '../models/games';
import LoadingButton from '../components/LoadingButton';
import FormField from '../components/FormField';
import { FaFootballBall, FaCalendarAlt, FaMapMarkerAlt, FaUsers, FaClock, FaGlobe, FaLock, FaChevronUp, FaFutbol } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { DayPicker } from 'react-day-picker'; // Add react-day-picker
import 'react-day-picker/dist/style.css'; // Add react-day-picker styles
import LocationPicker from '../components/LocationPicker'; // Import the custom LocationPicker
import TimePicker from '../components/TimePicker'; // Import the custom TimePicker
import AdvancedGameOptions from '../components/AdvancedGameOptions'; // Add this import
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'; // Ensure this import is present

const CreateGamePage: React.FC = () => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [scheduledAt, setScheduledAt] = useState<Date | null>(new Date()); // Set default to current date
	const [location, setLocation] = useState('');
	const [coordinates, setCoordinates] = useState<{ lat: number; lng: number } | null>(null); // New state
	const [maxMembers, setMaxMembers] = useState<number>(22);
	const [isCreating, setIsCreating] = useState(false);
	const [createError, setCreateError] = useState('');
	const navigate = useNavigate();
	const [selectedTime, setSelectedTime] = useState<Date | null>(new Date());

	// Add state variables for More Options and Team Sizes
	const [showMoreOptions, setShowMoreOptions] = useState(false);
	const [teamSizeLeft, setTeamSizeLeft] = useState(11);
	const [teamSizeRight, setTeamSizeRight] = useState(11);

	const [gameVisibility, setGameVisibility] = useState<'public' | 'unlisted'>('public'); // New state

	const handleCreateGame = async () => {
		setIsCreating(true);

		try {
			// Combine date and time into a single Date object
			let combinedDate = scheduledAt;
			if (scheduledAt && selectedTime) {
				combinedDate = new Date(
					scheduledAt.getFullYear(),
					scheduledAt.getMonth(),
					scheduledAt.getDate(),
					selectedTime.getHours(),
					selectedTime.getMinutes(),
					0, // seconds
					0 // milliseconds
				);
			}

			const newGame = new Game({
				name,
				description,
				scheduled_at: combinedDate,
				location,
				latitude: coordinates ? coordinates.lat : null,
				longitude: coordinates ? coordinates.lng : null,
				max_members: maxMembers,
				visibility: gameVisibility, // Add visibility
			});
			const createdGame = await newGame.create();
			navigate(`/dashboard`);
		} catch (error) {
			console.error('Failed to create game:', error);
			setCreateError('Failed to create game. Please try again.');
		} finally {
			setIsCreating(false);
		}
	};

	return (
		<div className="min-h-screen bg-gray-50">
			<TopNavBar />

			<div className="container mx-auto px-4 py-8">
				{/* Adjust the grid to have proper column widths */}
				<div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
					{/* Left Column: Controls */}
					<div className="lg:col-span-2">{/* Left column content or leave empty */}</div>

					{/* Center Column: Main Form */}
					<div className="lg:col-span-8 bg-white rounded-lg shadow-lg p-6">
						<div className="space-y-6">
							{/* Game Name */}
							<FormField label="Title" icon={<FaFutbol className="text-gray-400" />}>
								<div className="flex space-x-2 overflow-x-auto pb-2">
									<input
										type="text"
										className="w-full focus:outline-none rounded-md min-h-[24px]"
										value={name}
										onChange={(e) => setName(e.target.value)}
										placeholder="Game Title"
									/>
								</div>
							</FormField>

							{/* <FormField label=""> */}
								<div className="w-full md:w-auto mx-auto md:mx-0 items-center">
									<DayPicker
										mode="single"
										selected={scheduledAt}
										onSelect={(date: Date) => setScheduledAt(date)}
										className="w-full p-3 focus:outline-none rounded-md min-h-[42px]"
										// Additional styling can be applied here for theming
									/>
								</div>
							{/* </FormField> */}
							<FormField label="Kickoff Time">
								<TimePicker selectedTime={selectedTime} onChange={setSelectedTime} />
							</FormField>
							{/* Location */}
							<LocationPicker location={location} setLocation={setLocation} setCoordinates={setCoordinates} />

							{/* More Options */}
							<div className="mt-4">
								<button
									onClick={() => setShowMoreOptions(!showMoreOptions)}
									className="text-indigo-600 font-medium hover:underline flex items-center"
									style={{ minWidth: '120px' }} // Ensure button width remains constant
								>
									<div className="flex items-center">
										{showMoreOptions ? <FaChevronUp /> : <FaChevronDown />}
										<span className="ml-1">{showMoreOptions ? 'Less' : 'More'}</span>
									</div>
								</button>
							</div>

							{showMoreOptions && (
								<div className="mt-4">
									<label className="block text-sm font-medium text-gray-500 mb-1">Team Size</label>
									<div className="flex items-center space-x-4">
										<input
											type="number"
											value={teamSizeLeft}
											onFocus={(e) => e.target.select()} // Select all on focus
											onChange={(e) => {
												const value = parseInt(e.target.value) || 0;
												setTeamSizeLeft(value);
												setTeamSizeRight(value);
											}}
											className="bg-white border border-gray-100 rounded px-3 py-1 w-20"
											min={1}
										/>
										<span>vs</span>
										<input
											type="number"
											value={teamSizeRight}
											onFocus={(e) => e.target.select()} // Select all on focus
											onChange={(e) => setTeamSizeRight(parseInt(e.target.value) || 0)}
											className="bg-gray-50 border border-gray-100 rounded px-3 py-1 w-20"
											min={1}
										/>
									</div>

									<div className="mt-6">
										<div className="flex space-x-4">
											<label className="flex items-center">
												<input
													type="radio"
													name="visibility"
													value="public"
													checked={gameVisibility === 'public'}
													onChange={() => setGameVisibility('public')}
													className="form-radio text-indigo-600"
												/>
												<FaGlobe className="ml-2 text-gray-400" />
												<span className="ml-1">Public</span>
											</label>
											<label className="flex items-center">
												<input
													type="radio"
													name="visibility"
													value="unlisted"
													checked={gameVisibility === 'unlisted'}
													onChange={() => setGameVisibility('unlisted')}
													className="form-radio text-indigo-600"
												/>
												<FaLock className="ml-2 text-gray-400" />
												<span className="ml-1">Unlisted</span>
											</label>
										</div>
									</div>
								</div>
							)}

							{/* Description */}
							{/* Suggested Templates */}
							{/* <div className="flex space-x-2 overflow-x-auto pb-2">
                {[
                  'Join us for a fun match!',
                  'Looking for competitive players.',
                  'Beginners welcome!',
                ].map(template => (
                  <button
                    key={template}
                    onClick={() => setDescription(template)}
                    className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded-full text-sm"
                  >
                    {template}
                  </button>
                ))}
              </div>
              <textarea
                className="w-full p-3 focus:outline-none rounded-md border border-gray-300"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                placeholder="Add a short description"
              />
            </FormField> */}
							{/* Error Message */}
							{createError && <p className="text-red-300 mt-2">{createError}</p>}
							{/* Create Button */}
							<div className="mt-6">
								<LoadingButton
									isLoading={isCreating}
									onClick={handleCreateGame}
									aria-label="Creating..."
									disabled={isCreating}
									buttonClass={`w-full ${
										isCreating ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
									} text-white py-3 rounded-full transition duration-300`}
								>
									Create Game
								</LoadingButton>
							</div>
						</div>
					</div>
				</div>

				{/* Right Column: Advanced Options */}
				<div className="lg:col-span-2"></div>
			</div>
		</div>
	);
};

export default CreateGamePage;
