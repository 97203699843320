import React, { useState, useEffect } from 'react';
import TopNavBar from '../components/TopNavBar';
import UserProfileCard from '../components/UserProfileCard';
import Memberships from '../components/Memberships';
import FriendsList from '../components/FriendsList';
import GamesList from '../components/GamesList';
import BottomNavBar from '../components/BottomNavBar';
import FloatingActionButtons from '../components/FloatingActionButtons';
import { useUserGames } from '../hooks/useUserGames';
import { Waiver } from '../components/Waiver';
import Modal from '../components/Modal';
// Import the API function to fetch user data
import { User } from '../models/user';
import { Game } from '../models/games';
import Loading from '../components/Loading'; // Import the Loading component
import { useUserTeams } from '../hooks/useUserTeams';
// Import useNavigate for navigation
import { useNavigate } from 'react-router-dom';
import TeamStandings from '../components/TeamStandings';
import { Team, Standing } from '../models/teams';

const Dashboard: React.FC = () => {
	const { userGames, isGamesLoading, error, addGame } = useUserGames();
	const navigate = useNavigate(); // Initialize useNavigate

	const [activeTab, setActiveTab] = useState('forYou');
	const [filter, setFilter] = useState('all'); // 'all', 'upcoming', 'nearby'
	const [userProfile, setUserProfile] = useState<User | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isWaiverSigned, setIsWaiverSigned] = useState<boolean | null>(null);
	const { userTeams, isTeamsLoading, error: teamsError, addTeam } = useUserTeams();
	const [standings, setStandings] = useState<Standing[]>([]);

	useEffect(() => {
		const loadUserProfile = async () => {
			try {
				const userData = await User.fetchCurrentUser();
				setUserProfile(userData);
        const user = new User(userData);
				setIsWaiverSigned(await user.getUserWaiver()); // Check if waiver is signed
			} catch (error) {
				console.error('Error fetching user profile:', error);
			} finally {
				setIsLoading(false);
			}
		};

		loadUserProfile();
	}, []);

	useEffect(() => {
		const fetchAllStandings = async () => {
			if (userTeams && userTeams.length > 0) {
				try {
					const standingsData = await Promise.all(
						userTeams.map(async (teamData) => {
							const team = new Team(teamData);
							const teamStanding = await team.getStanding();
							return {
								...teamStanding,
								team_name: team.name,
							};
						})
					);
          // Sort standings by position
          const sortedStandings = standingsData.slice().sort((a, b) => a.position - b.position);
					setStandings(sortedStandings);
				} catch (error) {
					console.error('Error fetching standings:', error);
				}
			}
		};

		fetchAllStandings();
	}, [userTeams]);

	// Add InlineLoader component
	const InlineLoader: React.FC = () => {
		return (
			<div className="flex justify-center items-center py-10" role="status" aria-live="polite">
				<svg
					className="animate-spin h-8 w-8 text-indigo-600"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					aria-hidden="true"
				>
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
					<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
				</svg>
				<span className="ml-2 text-gray-600">Loading games...</span>
			</div>
		);
	};

	// Function to handle waiver signed status
	const handleWaiverSigned = () => {
		setIsWaiverSigned(true);
	};

	return (
		<div className="min-h-screen bg-gray-50 relative">
			{/* Top Navigation Bar */}
			<TopNavBar />

			{/* Main Content */}
			<div className="container mx-auto px-4 py-8">
				<div className="md:flex md:gap-8">
					{/* Left Column */}
					<div className="md:w-1/3 lg:w-1/4 hidden md:block overflow-y-auto max-h-screen">
						<UserProfileCard />
						{/* <Memberships memberships={memberships} />
            <FriendsList friends={friends} /> */}
					</div>

					{/* Right Column */}
					<div className="md:w-2/3 lg:w-3/4">
						{/* Tabs for Mobile */}
						<div className="md:hidden mb-4">
							<div className="flex justify-around border-b py-2 bg-white shadow-sm">
								<button
									className={`flex-1 text-center ${activeTab === 'forYou' ? 'text-indigo-600' : 'text-gray-500'}`}
									onClick={() => setActiveTab('forYou')}
								>
									<span className="text-sm font-semibold">For You</span>
								</button>
								<button
									className={`flex-1 text-center ${activeTab === 'upcoming' ? 'text-indigo-600' : 'text-gray-500'} opacity-50 cursor-not-allowed`}
									onClick={() => setActiveTab('upcoming')}
									disabled
								>
									<span className="text-sm font-semibold">Upcoming</span>
								</button>
								<button
									className={`flex-1 text-center ${activeTab === 'nearby' ? 'text-indigo-600' : 'text-gray-500'} opacity-50 cursor-not-allowed`}
									onClick={() => setActiveTab('nearby')}
									disabled
								>
									<span className="text-sm font-semibold">Nearby</span>
								</button>
							</div>
						</div>

						{/* Filter Options for Desktop */}
						<div className="hidden md:flex justify-end mb-4">
							<div className="space-x-4">
								<button
									className={`px-4 py-2 rounded-full ${filter === 'all' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'}`}
									onClick={() => setFilter('all')}
								>
									All Games
								</button>
								<button
									className={`px-4 py-2 rounded-full ${filter === 'upcoming' ? 'bg-indigo-600 text-white opacity-50 cursor-not-allowed' : 'bg-gray-100 text-gray-300'}`}
									onClick={() => setFilter('upcoming')}
									disabled
								>
									Upcoming
								</button>
								<button
									className={`px-4 py-2 rounded-full ${filter === 'nearby' ? 'bg-indigo-600 text-white opacity-50 cursor-not-allowed' : 'bg-gray-100 text-gray-300'}`}
									onClick={() => setFilter('nearby')}
									disabled
								>
									Nearby
								</button>
							</div>
						</div>

						{/* Team Standings Section */}
						<div className="mt-8">
							{standings.length > 0 ? (
								<TeamStandings standings={standings} userID={userProfile?.user_id} />
							) : (
								<p className="text-gray-500">You are not a member of any teams.</p>
							)}
						</div>

						{/* Handle Games List */}
						{userGames && userGames.length > 0 && <GamesList games={userGames} userID={userProfile?.user_id} />}

						{/* Show Loading Indicator */}
						{isGamesLoading && <InlineLoader />}

						{/* Handle Error State */}
						{error && <p className="text-red-600">Error loading games: {error.message}</p>}

						{/* Handle No Games Found */}
						{!isGamesLoading && userGames && userGames.length === 0 && (
							<div className="flex flex-col items-center justify-center py-20">
								<div
									className="flex flex-col items-center justify-center cursor-pointer transition-transform transform hover:scale-105 hover:bg-gray-100 p-4 rounded-xl"
									onClick={() => navigate('/create-game')}
								>
									<svg
										className="w-16 h-16 text-gray-400"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
									</svg>
								</div>
								<h2 className="text-xl font-semibold text-gray-700 mb-2 mt-2">No Games Found</h2>
								<p className="text-gray-500">Create a game to get started</p>
								{/* <FloatingActionButtons /> */}
							</div>
						)}
					</div>
				</div>
			</div>

			{/* Bottom Navigation Bar for Mobile */}
			{/* <BottomNavBar activeTab={activeTab} setActiveTab={setActiveTab} /> */}

			{/* Floating Action Buttons */}
			{/* <FloatingActionButtons /> */}

			{/* Waiver Modal */}
			{isWaiverSigned === false && (
				<Modal>
					<Waiver onWaiverSigned={handleWaiverSigned} />
				</Modal>
			)}
		</div>
	);
};

export default Dashboard;
