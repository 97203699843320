import { useState, useEffect, useRef } from 'react';
import { User } from '../models/user';

const originalWaiverText = `
# Waiver of Liability and Release Form
This form must be completed for each participant (player, coach) and, if the participant is under 18-years old, must be signed by the participant's parent or legal guardian. No one will be allowed to participate in "Kickoff Championships" without this form, properly executed, and on file.
PARTICIPANT'S NAME (type or print): _______________________________
PARTICIPANT'S DATE OF BIRTH (mm/dd/yyyy): _______________________

I, the undersigned, in consideration for my voluntary participation in organized soccer, do hereby willfully acknowledge that my signature below attests to my understanding and agreement that:

My participant status will be kept in good standing. I will not compromise myself in such a way as to harm the tournament, knowing that participants may be dismissed from participation, with possible loss of payment or dues, for violent conduct or unsportsmanlike behavior on or off the field of play. I agree to pay for any and all damages to any property or indemnities caused by me willfully, negligently, or otherwise.

Soccer is a physical, contact, sport that involves the risk of injury. I assume all risks and hazards associated with my participation in the sport. I am in proper physical condition to participate in Soccer games and have no illness, disease, existing injury, or physical defect that would be aggravated by my participation. I will inform my coach and the organizers if this status changes. I further acknowledge that this risk may involve loss or damage to me or my property, including the risk of death, or other unforeseen consequences, including those which may be due to the unavailability of immediate emergency medical care. I will wear shinguards, properly-fitted and appropriate shoes, as provided by soccer rules, to this event.

The tournament does not have personal injury insurance that covers my participation. Therefore, I should have a current, active, personal injury insurance policy in force, which covers my participation. Under any condition, I am responsible for any and all medical expenses arising from my participation, both in practices and games and while traveling to and from these events. I have the right and responsibility to inspect the equipment and facilities prior to events and, if I believe that anything may be unsafe, I will advise the tournament organizer of the condition and may refuse to participate. Participation assumes consent.

I authorize my photograph, picture or likeness, and voice to appear in any documentary, promotion (including advertising), television, video, or radio coverage of the league or tournament, without compensation.

I authorize that an unaltered copy of this form may be generated and given to the officers or directors of the tournament in order to allow my participation.

I hereby release, waive liability, discharge, hold harmless, indemnify, and covenant not to sue, Kickoff Games, its members and associations, their tournaments, their associated executives, administrators, officers, managers, volunteers, sponsors and advertisers, and other agents, estates or executors, from any and all liability incurred in the conduct of, and my participation in, their soccer programs. This includes owners, lessors, and lessees of premises, municipalities, government agencies, successors, heirs, and assigns.

I have completely read this document and fully understand its contents. I acknowledge that I have given up substantial rights by accepting this document and that I do so voluntarily. My signature attests to this on behalf of myself and my executors, personal representatives, administrators, heirs, next-of-kin, successors, and assigns.

For those individuals eighteen (18) years of age and older:

PARTICIPANT'S NAME (type or print): _______________________________
PARTICIPANT'S INITIALS: _________        DATE SIGNED: ________________


For those individuals under the age of eighteen (18) years (minor):

As the parent and natural guardian or legal guardian of the participant, I hereby agree to the foregoing Waiver of Liability and Release for, and on behalf of, the participant (player/minor) named above. I hereby bind myself, the minor, and all other assigns to the terms of the Waiver of Liability and Release. I represent and certify that I have the legal capacity and the authority to act for, and on behalf of, the minor in the execution of this Waiver of Liability and Release.

PARENT/GUARDIAN NAME (PRINT): ________________________________
PARENT/GUARDIAN INITIALS: _________        DATE SIGNED: ________________

Concussion Awareness and Waiver
A concussion is a type of traumatic brain injury that changes the way the brain normally works. A concussion is caused by a bump, blow or jolt to the head or body that causes the head and brain to move rapidly back and forth. Even a "ding", "getting your bell rung" or what seems to be a mild bump or blow to the head can be serious.

### WHAT ARE THE SIGNS AND SYMPTOMS OF A CONCUSSION?

Signs and symptoms of a concussion can show up right after the injury or may appear days or weeks after the injury. If a player reports one or more symptoms of concussions listed below: a bump, blow or jolt to the head or body, they should be kept out of play the day of the injury and until a health care professional, experienced in evaluating concussions, says they are symptom-free and they are OK to return to play.

#### Signs Observed by Coaching Staff:
- Appears dazed or stunned
- Is confused about position or assignment
- Forgets an instruction
- Is unsure of game score or opponent
- Moves clumsily
- Answersquestionsslowly
- Loses consciousness (even brie y)
- Shows mood, behavior or personality changes
- Cannot recall events prior to hit or fall
- Cannot recall events after hit or fall

#### Symptoms Reported by Players:
- Headaches or "pressure" in the head
- Nausea or vomiting
- Balance problems or dizziness
- Double or blurry vision
- Sensitivity to noise
- Feeling sluggish, hazy, foggy or groggy
- Concentration on or memory problems
- Confusion
- Just not "feeling right" or "feeling down"

### CONCUSSION DANGER SIGNS
In rare cases, a dangerous blood clot may form on the brain in a person with a concussion and crowd the brain against the skull. A player should receive immediate medical a en on if a er a bump, blow or jolt to the head or body they exhibit any of the following danger signs:
- One pupil larger than the other
- Is drowsy or cannot be awakened
- A headache that gets worse or slurred speech
- Weakness, numbness or decreased coordination
- Convulsions, seizures or unusual behavior
- Cannot recognize people or places
- Becomes increasingly confused or agitated
- Loses consciousness for any amount of me

WHY SHOULD A PLAYER REPORT THEIR SYMPTOMS?
If a player has a concussion, his/her brain needs time to heal. While a player's brain is healing, they are much more likely to have another concussion. Repeat concussions can increase the time it takes to recover. In rare cases, repeat concussions in young players can result in brain swelling or permanent damage to their brain. It can even be fatal.

WHAT SHOULD YOU DO IF YOU THINK YOUR PLAYER HAS A CONCUSSION?
If you suspect that a player has a concussion, remove them from play and seek medical attention. Do not try to judge the severity of the injury yourself. Keep the player out of play until a medical professional says they are symptom-free and are OK to return to play. Rest is the key to helping a player recover. Exercising or activities that involve a lot of concentration (studying, computers, video games) may cause concussion symptoms to reappear or worsen. After a concussion, returning to sports and school is a gradual process that should be carefully managed and monitored by a healthcare professional.

I, hereby acknowledge having received education about the signs, symptoms and risks of sport-related concussion as provided in the information on above.

For those individuals eighteen (18) years of age and older:

PARTICIPANT'S NAME (type or print): _______________________________
PARTICIPANT'S INITIALS: _________        DATE SIGNED: ________________

For those individuals under the age of eighteen (18) years (minor):
As the parent and natural guardian or legal guardian of the participant, I hereby acknowledge having received education about the signs, symptoms and risks of sport related concussion as provided in the information above.

PARENT/GUARDIAN NAME (PRINT)
PARENT/GUARDIAN SIGNATURE
DATE SIGNED


`;

interface Errors {
    participantName?: string;
    participantInitials?: string;
    participantDOB?: string;
    dateSigned?: string;
    parentName?: string;
    parentInitials?: string;
    parentDateSigned?: string;
}

export function Waiver({ onWaiverSigned }) {
    const [formData, setFormData] = useState({
        participantName: '',
        participantInitials: '',
        participantDOB: '',
        dateSigned: '',
        isMinor: false,
        parentName: '',
        parentInitials: '',
        parentDateSigned: '',
    });
    const [errors, setErrors] = useState<Errors>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    // Refs for input fields (kept for potential use)
    const participantNameRef = useRef(null);
    const participantInitialsRef = useRef(null);
    const participantDOBRef = useRef(null);
    const dateSignedRef = useRef(null);
    const isMinorRef = useRef(null);
    const parentNameRef = useRef(null);
    const parentInitialsRef = useRef(null);
    const parentDateSignedRef = useRef(null);

    // Removed auto-advance effect
    // useEffect(() => {
    //     if (formData.isMinor && parentNameRef.current) {
    //         parentNameRef.current.focus();
    //     }
    // }, [formData.isMinor]);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });

        // Real-time validation
        validateField(name, value);
    };

    // Validate individual fields
    const validateField = (fieldName: string, value: string) => {
        let fieldErrors: Errors = { ...errors };
        switch (fieldName) {
            case 'participantName':
                if (!value.trim()) {
                    fieldErrors.participantName = 'Participant\'s name is required.';
                } else {
                    delete fieldErrors.participantName;
                    // Removed auto-advance
                    // if (participantInitialsRef.current) participantInitialsRef.current.focus();
                }
                break;
            case 'participantInitials':
                if (!value.trim()) {
                    fieldErrors.participantInitials = 'Participant\'s initials are required.';
                } else {
                    delete fieldErrors.participantInitials;
                    // Removed auto-advance
                    // if (participantDOBRef.current) participantDOBRef.current.focus();
                }
                break;
            case 'participantDOB':
                if (!value.trim()) {
                    fieldErrors.participantDOB = 'Participant\'s date of birth is required.';
                } else {
                    delete fieldErrors.participantDOB;
                    // Removed auto-advance
                    // if (dateSignedRef.current) dateSignedRef.current.focus();
                }
                break;
            case 'dateSigned':
                if (!value.trim()) {
                    fieldErrors.dateSigned = 'Date signed is required.';
                } else {
                    delete fieldErrors.dateSigned;
                    // Removed auto-advance
                    // if (isMinorRef.current) isMinorRef.current.focus();
                }
                break;
            case 'parentName':
                if (formData.isMinor && !value.trim()) {
                    fieldErrors.parentName = 'Parent/Guardian name is required for minors.';
                } else {
                    delete fieldErrors.parentName;
                    // Removed auto-advance
                    // if (parentInitialsRef.current) parentInitialsRef.current.focus();
                }
                break;
            case 'parentInitials':
                if (formData.isMinor && !value.trim()) {
                    fieldErrors.parentInitials = 'Parent/Guardian initials are required for minors.';
                } else {
                    delete fieldErrors.parentInitials;
                    // Removed auto-advance
                    // if (parentDateSignedRef.current) parentDateSignedRef.current.focus();
                }
                break;
            case 'parentDateSigned':
                if (formData.isMinor && !value.trim()) {
                    fieldErrors.parentDateSigned = 'Date signed by Parent/Guardian is required for minors.';
                } else {
                    delete fieldErrors.parentDateSigned;
                }
                break;
            default:
                break;
        }
        setErrors(fieldErrors);
    };

    // Validate the entire form before submission
    const validateForm = () => {
        let fieldErrors: Errors = {};
        if (!formData.participantName.trim()) {
            fieldErrors.participantName = 'Participant\'s name is required.';
        }
        if (!formData.participantInitials.trim()) {
            fieldErrors.participantInitials = 'Participant\'s initials are required.';
        }
        if (!formData.participantDOB.trim()) {
            fieldErrors.participantDOB = 'Participant\'s date of birth is required.';
        }
        if (!formData.dateSigned.trim()) {
            fieldErrors.dateSigned = 'Date signed is required.';
        }
        if (formData.isMinor) {
            if (!formData.parentName.trim()) {
                fieldErrors.parentName = 'Parent/Guardian name is required for minors.';
            }
            if (!formData.parentInitials.trim()) {
                fieldErrors.parentInitials = 'Parent/Guardian initials are required for minors.';
            }
            if (!formData.parentDateSigned.trim()) {
                fieldErrors.parentDateSigned = 'Date signed by Parent/Guardian is required for minors.';
            }
        }
        setErrors(fieldErrors);
        return Object.keys(fieldErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setIsSubmitting(true);
        try {
            const waiverData = {
                ...formData,
                waiverText: generateWaiverText(),
            };
            const user = await User.fetchCurrentUser();
            await user.setUserWaiver(JSON.stringify(waiverData));
            onWaiverSigned();
        } catch (error) {
            console.error('Error signing waiver:', error);
            // Handle error (e.g., show a notification to the user)
        } finally {
            setIsSubmitting(false);
        }
    };

    // Generate waiver text with participant's name and initials
    const generateWaiverText = () => {
        let text = originalWaiverText;
        text = text.replace(/PARTICIPANT'S NAME \(type or print\): _______________________________/, `PARTICIPANT'S NAME: ${formData.participantName}`);
        text = text.replace(/PARTICIPANT'S DATE OF BIRTH \(mm\/dd\/yyyy\): _______________________/,
            `PARTICIPANT'S DATE OF BIRTH: ${formData.participantDOB}`);
        text = text.replace(/I, the undersigned,/, `I, ${formData.participantName}, the undersigned,`);
        text = text.replace(/PARTICIPANT'S NAME \(type or print\): _______________________________/, `PARTICIPANT'S NAME: ${formData.participantName}`);
        text = text.replace(/PARTICIPANT'S INITIALS: _________/, `PARTICIPANT'S INITIALS: ${formData.participantInitials}`);
        text = text.replace(/DATE SIGNED: ________________/, `DATE SIGNED: ${formData.dateSigned}`);

        if (formData.isMinor) {
            text = text.replace(/PARENT\/GUARDIAN NAME \(PRINT\): ________________________________/,
                `PARENT/GUARDIAN NAME: ${formData.parentName}`);
            text = text.replace(/PARENT\/GUARDIAN INITIALS: _________/, `PARENT/GUARDIAN INITIALS: ${formData.parentInitials}`);
            text = text.replace(/DATE SIGNED: ________________/, `DATE SIGNED: ${formData.parentDateSigned}`);
        }

        return text;
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            {/* Waiver Text */}
            <div className="mb-6 p-6 border rounded-md bg-white shadow overflow-y-auto max-h-screen">
                <pre className="whitespace-pre-wrap text-gray-800 text-lg leading-relaxed">
                    {generateWaiverText()}
                </pre>
            </div>

            {/* Waiver Form */}
            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Participant's Information */}
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Participant's Name (PRINT)
                    </label>
                    <input
                        type="text"
                        name="participantName"
                        value={formData.participantName}
                        onChange={handleChange}
                        ref={participantNameRef}
                        required
                        className={`mt-1 block w-full rounded-md border ${errors.participantName ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pl-2`}
                        placeholder="Enter participant's full name"
                        autoFocus
                        onBlur={() => validateField('participantName', formData.participantName)}
                    />
                    {errors.participantName && <p className="mt-1 text-sm text-red-600">{errors.participantName}</p>}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Participant's Initials
                    </label>
                    <input
                        type="text"
                        name="participantInitials"
                        value={formData.participantInitials}
                        onChange={handleChange}
                        ref={participantInitialsRef}
                        required
                        maxLength={3}
                        className={`mt-1 block w-24 rounded-md border ${errors.participantInitials ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pl-2`}
                        placeholder="Initials"
                        onBlur={() => validateField('participantInitials', formData.participantInitials)}
                    />
                    {errors.participantInitials && <p className="mt-1 text-sm text-red-600">{errors.participantInitials}</p>}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Participant's Date of Birth
                    </label>
                    <input
                        type="date"
                        name="participantDOB"
                        value={formData.participantDOB}
                        onChange={handleChange}
                        ref={participantDOBRef}
                        required
                        className={`mt-1 block w-48 rounded-md border ${errors.participantDOB ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pl-2`}
                        onBlur={() => validateField('participantDOB', formData.participantDOB)}
                    />
                    {errors.participantDOB && <p className="mt-1 text-sm text-red-600">{errors.participantDOB}</p>}
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Date Signed
                    </label>
                    <input
                        type="date"
                        name="dateSigned"
                        value={formData.dateSigned}
                        onChange={handleChange}
                        ref={dateSignedRef}
                        required
                        className={`mt-1 block w-48 rounded-md border ${errors.dateSigned ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pl-2`}
                        onBlur={() => validateField('dateSigned', formData.dateSigned)}
                    />
                    {errors.dateSigned && <p className="mt-1 text-sm text-red-600">{errors.dateSigned}</p>}
                </div>

                {/* Minor Checkbox */}
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        name="isMinor"
                        checked={formData.isMinor}
                        onChange={handleChange}
                        ref={isMinorRef}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label className="ml-2 block text-sm text-gray-700">
                        I am under 18 years of age
                    </label>
                </div>

                {/* Parent/Guardian Information */}
                {formData.isMinor && (
                    <>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Parent/Guardian Name (PRINT)
                            </label>
                            <input
                                type="text"
                                name="parentName"
                                value={formData.parentName}
                                onChange={handleChange}
                                ref={parentNameRef}
                                required
                                className={`mt-1 block w-full rounded-md border ${errors.parentName ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pl-2`}
                                placeholder="Enter parent/guardian's full name"
                                onBlur={() => validateField('parentName', formData.parentName)}
                            />
                            {errors.parentName && <p className="mt-1 text-sm text-red-600">{errors.parentName}</p>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Parent/Guardian Initials
                            </label>
                            <input
                                type="text"
                                name="parentInitials"
                                value={formData.parentInitials}
                                onChange={handleChange}
                                ref={parentInitialsRef}
                                required
                                maxLength={3}
                                className={`mt-1 block w-24 rounded-md border ${errors.parentInitials ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pl-2`}
                                placeholder="Initials"
                                onBlur={() => validateField('parentInitials', formData.parentInitials)}
                            />
                            {errors.parentInitials && <p className="mt-1 text-sm text-red-600">{errors.parentInitials}</p>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Date Signed by Parent/Guardian
                            </label>
                            <input
                                type="date"
                                name="parentDateSigned"
                                value={formData.parentDateSigned}
                                onChange={handleChange}
                                ref={parentDateSignedRef}
                                required
                                className={`mt-1 block w-48 rounded-md border ${errors.parentDateSigned ? 'border-red-500' : 'border-gray-300'} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 pl-2`}
                                onBlur={() => validateField('parentDateSigned', formData.parentDateSigned)}
                            />
                            {errors.parentDateSigned && <p className="mt-1 text-sm text-red-600">{errors.parentDateSigned}</p>}
                        </div>
                    </>
                )}

                {/* Submit Button */}
                <div>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${
                            isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
                        } text-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    >
                        {isSubmitting ? 'Submitting...' : 'Agree and Sign'}
                    </button>
                </div>
            </form>
        </div>
    );
}