import React from 'react';
import { FaClock, FaMapMarkerAlt } from 'react-icons/fa';
import { Game } from '../models/games';
import { useState, useEffect } from 'react';

interface GameCardProps {
  game: Game;
  userID: string;
}

const GameCard: React.FC<GameCardProps> = ({ game, userID }) => {
  const date = new Date(game.scheduled_at);
  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  // Add state variables
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [gameEvents, setGameEvents] = useState('');
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (isEventsOpen) {
      // Fetch game events when the box is opened
      Game.getGameEvents(game.game_id).then((events) => {
        setGameEvents(events);
      });

      // Check if the user is an admin
      if (userID === '6d3307c0-3683-4247-944d-4dd4d2810865') {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }
    }
  }, [isEventsOpen]);

  // Toggle the events box when the card is clicked
  const handleCardClick = () => {
    setIsEventsOpen(!isEventsOpen);
  };

  // Handle changes to the game events text
  const handleEventsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGameEvents(e.target.value);
  };

  // Save the game events when the textarea loses focus
  const handleEventsBlur = () => {
    if (isEditable) {
      Game.updateGameEvents(game.game_id, gameEvents);
    }
  };

  return (
    <div
      className="flex items-stretch bg-white shadow rounded-lg overflow-hidden mb-4"
      onClick={handleCardClick}
    >
      {/* Date Section */}
      <div className="flex flex-col items-center justify-center w-20 bg-indigo-600 text-white">
        <div className="text-2xl font-bold">{date.getDate()}</div>
        <div className="text-sm uppercase">{date.toLocaleString('en-US', { month: 'short' })}</div>
      </div>

      {/* Divider Line */}
      <div className="h-full w-px bg-gray-200"></div>

      {/* Content Section */}
      <div className="flex flex-col justify-center p-4 flex-grow">
        {/* Game Title */}
        <h2 className="text-xl font-semibold text-gray-800 mb-2">{game.name}</h2>

        {/* Game Details */}
        <div className="flex items-center text-gray-600 text-sm mb-1">
          <FaClock className="mr-2 text-gray-400" />
          <span>{`${formattedDate} at ${formattedTime}`}</span>
        </div>
        {game.location && (
          <div className="flex items-center text-gray-600 text-sm">
            <FaMapMarkerAlt className="mr-2 text-gray-400" />
            <span>{game.location}</span>
          </div>
        )}
      </div>

      {/* Game Events Box */}
      {isEventsOpen && (
        <div className="p-4">
          <textarea
            className="w-full text-gray-800 text-sm outline-none resize-none bg-transparent"
            value={gameEvents}
            onChange={handleEventsChange}
            onBlur={handleEventsBlur}
            readOnly={!isEditable}
          />
        </div>
      )}
    </div>
  );
};

export default GameCard;
