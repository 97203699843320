import { useEffect, useState } from 'react';
import { User, Friend } from '../models/user';

export function useFriends() {
  const [friends, setFriends] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  async function fetchFriends() {
    try {
      setLoading(true);
      setError(null);

      const friendsList = await User.getFriends();
      setFriends(friendsList);
    } catch (err) {
      setError('Failed to load friends');
    } finally {
      setLoading(false);
    }
  }

  async function getFriendStatus(friendId: string): Promise<Friend> {
    try {
      const status = await User.getFriendStatus(friendId);
      return status;
    } catch (err) {
      throw new Error('Failed to get friend status');
    }
  }

  useEffect(() => {
    fetchFriends();
  }, []);

  return {
    friends,
    loading,
    error,
    refresh: fetchFriends, // Expose the fetch function for manual refreshes
    getFriendStatus,       // Expose the function to get friend status
  };
}
