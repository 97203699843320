import React, { useState, useContext } from 'react';
import { Standing } from '../models/teams';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Team } from '../models/teams';

interface TeamStandingsProps {
	standings: Standing[];
    userID: string;
}

const TeamStandings: React.FC<TeamStandingsProps> = ({ standings, userID }) => {
	// Sort standings by position
	const sortedStandings = standings.slice().sort((a, b) => b.points - a.points);

	const adminUserID = '6d3307c0-3683-4247-944d-4dd4d2810865'; // Replace with the actual admin userID

	// Use state to manage editable standings
	const [editableStandings, setEditableStandings] = useState(sortedStandings);

	// Function to handle drag end
	const handleDragEnd = (result) => {
		if (!result.destination) return;

		const items = Array.from(editableStandings);
		const [movedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, movedItem);

		// Update positions based on new order
		const updatedStandings = items.map((item, index) => ({
			...item,
			position: index + 1,
		}));

		setEditableStandings(updatedStandings);

		// TODO: Send updated standings to the server
	};

	// Function to handle field change
	const handleFieldChange = (index, field, value) => {
		const updatedStandings = [...editableStandings];
		updatedStandings[index][field] = value;
		setEditableStandings(updatedStandings);

        const standing = updatedStandings[index];
        const teamID = standing.team_id;
        Team.updateStandings(teamID, standing);

		// TODO: Send updated item to the server
	};

	return (
		<div className="bg-white shadow rounded-lg mb-8">
			{/* <h3 className="text-lg font-semibold text-gray-700 px-6 py-4 bg-gray-100">
        Team Standings
      </h3> */}
			<div className="overflow-x-auto">
				{userID === adminUserID ? (
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="standings">
							{(provided) => (
								<table
									className="min-w-full divide-y divide-gray-200"
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									<thead>
										<tr className="bg-gray-100">
											{/* Removed Position column header */}
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Team</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Played</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Won</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Drawn</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lost</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goals For</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goals Against</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goal Diff</th>
											<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Points</th>
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200">
										{editableStandings.map((item, index) => (
											<Draggable
												key={item.team_id}
												draggableId={item.team_id}
												index={index}
											>
												{(provided) => (
													<tr
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={
															index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
														}
													>
														{/* Editable cells */}
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="text"
																value={item.team_name}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'team_name',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-2 py-1 w-32" // Adjusted width
															/>
														</td>
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="number"
																value={item.played}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'played',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center" // Adjusted width
															/>
														</td>
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="number"
																value={item.won}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'won',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center"
															/>
														</td>
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="number"
																value={item.drawn}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'drawn',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center"
															/>
														</td>
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="number"
																value={item.lost}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'lost',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center"
															/>
														</td>
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="number"
																value={item.goals_for}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'goals_for',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center"
															/>
														</td>
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="number"
																value={item.goals_against}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'goals_against',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center"
															/>
														</td>
														<td className="px-2 py-2 text-sm text-gray-900">
															<input
																type="number"
																value={item.goal_difference}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'goal_difference',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center"
															/>
														</td>
														<td className="px-2 py-2 bg-rose-50 whitespace-nowrap text-sm font-medium text-gray-500">
															<input
																type="number"
																value={item.points}
																onChange={(e) =>
																	handleFieldChange(
																		index,
																		'points',
																		e.target.value
																	)
																}
																className="border border-gray-300 rounded px-1 py-1 w-12 text-center"
															/>
														</td>
													</tr>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</tbody>
								</table>
							)}
						</Droppable>
					</DragDropContext>
				) : (
					// Original table body for regular users
					<table className="min-w-full divide-y divide-gray-200">
						<thead>
							<tr className="bg-gray-100">
								{/* Removed Position column header */}
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Team</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Played</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Won</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Drawn</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lost</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goals For</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goals Against</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Goal Diff</th>
								<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Points</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200">
							{sortedStandings.map((item, index) => (
								<tr
									key={index}
									className={
										index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
									}
								>
									{/* Read-only cells */}
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.team_name}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.played}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.won}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.drawn}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.lost}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.goals_for}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.goals_against}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item.goal_difference}</td>
									<td className="px-6 py-4 bg-rose-50 whitespace-nowrap text-sm font-medium text-gray-500">{item.points}</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default TeamStandings;
