import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Rules from './pages/Rules';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Contact from './pages/Contact';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import UnderConstruction from './pages/UnderConstruction';
import Dashboard from './pages/Dashboard';
import TermsAndConditions from './pages/TermsAndConditions';
import EditProfilePage from './pages/EditProfilePage';
import Verify from './pages/Verify';
import CreateGame from './pages/CreateGame';
import CreateTeamPage from './pages/CreateTeam';
import UserProfilePage from './pages/UserProfilePage';
config.autoAddCss = false;

function App() {
	// if address is championships-3pl.pages.dev, redirect to https://championships.kickoff.game
	if (window.location.hostname === 'championships-3pl.pages.dev') {
		window.location.href = 'https://kickoff.game';
	}
	if (window.location.hostname === 'championships.kickoff.game') {
		window.location.href = 'https://kickoff.game';
	}
	return (
		<div className="font-sans">
			<Router>
				<div className="App">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/rules" element={<Rules />} />
						<Route path="/signin" element={<SignIn />} />
						<Route path="/signup" element={<SignUp />} />
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/terms" element={<TermsAndConditions />} />
						<Route path="/editprofile" element={<EditProfilePage />} />
						<Route path="/register" element={<UnderConstruction />} />
						<Route path="/verify" element={<Verify />} />
						<Route path="/create-game" element={<CreateGame />} />
						<Route path="/create-team" element={<CreateTeamPage />} />
						<Route path="/u/:username" element={<UserProfilePage />} />
					</Routes>
				</div>
			</Router>
		</div>
	);
}

export default App;
