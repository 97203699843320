/**
 * useUserGames Hook
 *
 * This hook manages the user's games data, providing efficient caching and
 * optimistic updates for a responsive user experience.
 *
 * Functionality:
 * 1. Immediately returns cached games data if available and not expired.
 * 2. Asynchronously fetches and updates games data when needed.
 * 3. Implements local storage caching with a 24-hour expiration.
 * 4. Provides optimistic updates for adding new games.
 * 5. Manages loading and error states.
 *
 * Usage:
 * const { userGames, isGamesLoading, error, addGame } = useUserGames();
 *
 * - userGames: Array of Game objects or null if not loaded
 * - isGamesLoading: Boolean indicating if games are being fetched
 * - error: Error object if an error occurred during fetching
 * - addGame: Function to optimistically add a new game
 *
 * The hook will cause a re-render when:
 * - Initial cached data is loaded
 * - New games data is fetched
 * - A game is added (optimistically and after confirmation)
 * - Loading state changes
 * - An error occurs
 *
 * Cache behavior:
 * - If cached data exists and is not expired, it's used immediately
 * - If cache is expired or doesn't exist, new data is fetched from the server
 * - Cache expiration is set to 24 hours
 *
 * Note: This hook depends on the useUserProfile hook for the current user's ID.
 */

import { useState, useEffect } from 'react';
import { useUserProfile } from './useUserProfile';
import { Game } from '../models/games';

const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// Function to sort games by their scheduled date (ascending order)
const sortGamesByDate = (games: Game[]): Game[] => {
	return games.sort((a, b) => new Date(a.scheduled_at).getTime() - new Date(b.scheduled_at).getTime());
};

export const useUserGames = () => {
	const { userProfile } = useUserProfile();
	const [userGames, setUserGames] = useState<Game[] | null>(null); // Initialize as null
	const [isGamesLoading, setIsGamesLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);
	const [refreshKey, setRefreshKey] = useState(0);

	const refreshGames = () => {
		setRefreshKey((prev) => prev + 1);
	};

	useEffect(() => {
		if (!userProfile) {
			console.log('useUserGames: userProfile is not available');
			setIsGamesLoading(false);
			return; // Wait for userProfile
		}

		const CACHE_KEY = `userGamesCache_${userProfile.user_id}`;
		const cachedGames = localStorage.getItem(CACHE_KEY);

		if (cachedGames) {
			const { data, timestamp } = JSON.parse(cachedGames);
			if (Date.now() - timestamp < CACHE_EXPIRY) {
				let validData = data.filter(isValidGame);
				validData = sortGamesByDate(validData); // {{ edit_1 }}
				setUserGames(validData);
				console.log('useUserGames: Using cached and sorted games data'); // {{ edit_1 }}
				// Do not return here; proceed to fetch the latest data
			} else {
				localStorage.removeItem(CACHE_KEY); // Remove expired data
			}
		}

		const fetchNearbyGames = async () => {
			setIsGamesLoading(true);
			try {
				const games = await Game.getNearby(userProfile.user_id);
				let validGames = games.filter(isValidGame);
				validGames = sortGamesByDate(validGames); // {{ edit_2 }}
				setUserGames((prevGames) => {
					if (!prevGames) return validGames;
					const gameIds = new Set(prevGames.map((game) => game.game_id));
					const newUniqueGames = validGames.filter((game) => !gameIds.has(game.game_id));
					return sortGamesByDate([...prevGames, ...newUniqueGames]);
				});
			} catch (err) {
				// setError(err as Error);
				console.error('Error fetching nearby games:', err);
			} finally {
				setIsGamesLoading(false);
			}
		};

		const fetchUserGames = async () => {
			setIsGamesLoading(true);
			try {
				const games = await Game.getByUserId(userProfile.user_id);
				let validGames = games.filter(isValidGame);
				validGames = sortGamesByDate(validGames); // {{ edit_2 }}
				setUserGames((prevGames) => {
					if (!prevGames) return validGames;
					const gameIds = new Set(prevGames.map((game) => game.game_id));
					const newUniqueGames = validGames.filter((game) => !gameIds.has(game.game_id));
					return sortGamesByDate([...prevGames, ...newUniqueGames]);
				});
				localStorage.setItem(
					CACHE_KEY,
					JSON.stringify({
						data: validGames,
						timestamp: Date.now(),
					})
				);
				console.log('useUserGames: Games fetched, sorted, and cached successfully'); // {{ edit_2 }}
			} catch (err) {
				setError(err as Error);
				console.error('Error fetching user games:', err);
			} finally {
				setIsGamesLoading(false);
			}
		};

		fetchUserGames();
		fetchNearbyGames();
	}, [userProfile]); // Removed refreshKey from dependencies

	const addGame = async (newGame: Game) => {
		/* edit_3 */
		if (!isValidGame(newGame)) {
			console.warn('Attempted to add an invalid game:', newGame);
			return;
		}

		if (!userGames || !userProfile) return;

		const updatedGames = sortGamesByDate([...userGames, newGame]); // {{ edit_3 }}
		setUserGames((prevGames) => {
			if (!prevGames) return updatedGames;
			const gameIds = new Set(prevGames.map((game) => game.game_id));
			const newUniqueGames = updatedGames.filter((game) => !gameIds.has(game.game_id));
			return sortGamesByDate([...prevGames, ...newUniqueGames]);
		});

		const CACHE_KEY = `userGamesCache_${userProfile.user_id}`;
		localStorage.setItem(
			CACHE_KEY,
			JSON.stringify({
				data: updatedGames,
				timestamp: Date.now(),
			})
		);

		try {
			await newGame.create();
			console.log('useUserGames: Game added successfully');
			refreshGames(); // Trigger refresh to get latest data
		} catch (err) {
			// Revert the optimistic update on failure
			const revertedGames = userGames.filter((game) => game.game_id !== newGame.game_id);
			setUserGames((prevGames) => {
				if (!prevGames) return revertedGames;
				const gameIds = new Set(prevGames.map((game) => game.game_id));
				const newUniqueGames = revertedGames.filter((game) => !gameIds.has(game.game_id));
				return sortGamesByDate([...prevGames, ...newUniqueGames]);
			});
			localStorage.setItem(
				CACHE_KEY,
				JSON.stringify({
					data: sortGamesByDate(revertedGames), // {{ edit_4 }}
					timestamp: Date.now(),
				})
			);
			console.error('Error adding game:', err);
		}
	}; /* edit_3 */

	// Optionally, sort games after deletion if you implement the deleteGame function
	const deleteGame = async (gameId: string) => {
		/* edit_5 */
		if (!userGames || !userProfile) return;

		const CACHE_KEY = `userGamesCache_${userProfile.user_id}`;
		const originalGames = [...userGames];

		// Optimistically remove the game from state and cache
		const updatedGames = sortGamesByDate(userGames.filter((game) => game.game_id !== gameId)); // {{ edit_5 }}
		setUserGames((prevGames) => {
			if (!prevGames) return updatedGames;
			const gameIds = new Set(prevGames.map((game) => game.game_id));
			const newUniqueGames = updatedGames.filter((game) => !gameIds.has(game.game_id));
			return sortGamesByDate([...prevGames, ...newUniqueGames]);
		});
		localStorage.setItem(
			CACHE_KEY,
			JSON.stringify({
				data: updatedGames,
				timestamp: Date.now(),
			})
		);

		try {
			const game = await Game.fetchById(gameId);
			await game.delete(); // Ensure you have this method implemented
			console.log(`useUserGames: Game with ID ${gameId} deleted successfully`);
		} catch (err) {
			// Revert the optimistic update on failure
			setUserGames(sortGamesByDate(originalGames)); // {{ edit_5 }}
			localStorage.setItem(
				CACHE_KEY,
				JSON.stringify({
					data: sortGamesByDate(originalGames), // {{ edit_5 }}
					timestamp: Date.now(),
				})
			);
			console.error(`Error deleting game with ID ${gameId}:`, err);
		}
	}; /* edit_5 */

	return { userGames, isGamesLoading, error, addGame, deleteGame }; /* edit_start */
};

function isValidGame(game: Game): boolean {
	/* edit_2 */
	// Define your validation logic here
	// Example validation: Check for required fields and valid data types
	return (
		typeof game.game_id === 'string' &&
		typeof game.name === 'string' &&
		!isNaN(new Date(game.scheduled_at).getTime()) &&
		typeof game.location === 'string'
	);
} /* edit_2 */
